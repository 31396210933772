class State {
  constructor(value) {
    let _value = Number(value);
    this.value = _value;
    switch (_value) {
      case 0: {
        this.label = "未开始";
        break;
      }
      case 1: {
        this.label = "进行中";
        break;
      }
      case 2: {
        this.label = "已完成";
        break;
      }
      case 3: {
        this.label = "已中止";
        break;
      }
      case 4: {
        this.label = "已取消";
        break;
      }
    }
  }

  get started() {
    return this.value >= 1;
  }

  get completed() {
    return this.value == 2;
  }

  get failed() {
    return this.value == 3;
  }

  get canceled() {
    return this.value == 4;
  }
}

/**
 * 订单状态计算
 */
export class OrderState {
  /**
   * string
   * 订单状态字面量
   */
  state;
  /**
   *
   * @param {Number} insure 投保状态
   * @param {Number} underwriting  核保状态
   * @param {Number} pay  支付状态
   * @param {Number} issue 出单状态
   */
  constructor(confirm, issue, cancel, withdraw, insure, applyPolicy) {
    this.confirmState = new State(confirm);
    this.issueState = new State(issue);
    this.cancelState = new State(cancel);
    this.withdrawState = new State(withdraw);
    this.insureState = new State(insure);
    this.applyPolicyState = new State(applyPolicy);
    if (this.cancelState.started) {
      if (this.cancelState.completed) {
        this.state = "失效/拒保";
      }
    } else if (this.withdrawState.started) {
      // 退保状态
      if (this.withdrawState.completed && cancel !== 2) {
        this.state = "已退保";
      }
    } else if (this.issueState.started) {
      if (this.issueState.completed && cancel !== 2) {
        this.state = "已出单";
      }
    } else if (this.confirmState.started) {
      if (
        this.confirmState.completed &&
        (issue === 0 || issue === null) &&
        cancel !== 2 &&
        (applyPolicy === 0 || applyPolicy === 2)
      ) {
        // this.state = "已确认";
        if (this.insureState.completed) {
          this.state = "已确认(success)";
        } else if (this.insureState.failed) {
          this.state = "已确认(fail)";
        } else if (this.insureState.canceled) {
          this.state = "已确认(cancel)";
        } else {
          this.state = "已确认(start)";
        }
      } else if (
        this.confirmState.completed &&
        (issue === 0 || issue === null) &&
        cancel !== 2 &&
        applyPolicy === 1
      ) {
        this.state = "待确认";
      }
    } else {
      this.state = "待确认";
    }
  }
}
